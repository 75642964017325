<template>
  <v-card>
    <v-card-text>
      <v-data-table
        :headers="tableHeaders"
        :items="documentsList"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:item.viewAction="{ item }">
          <v-btn class="primary" @click="viewDocument(item)">View</v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import SelectFunctions from "../select/SelectFunctions";
import downloadjs from "downloadjs";

export default {
  name: "AppointmentDocuments",

  props: {
    appointment: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.loadDocuments();
  },

  methods: {
    async loadDocuments() {
      try {
        const response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT +
            "schedule/appointment/documents/" +
            this.appointment.id,
          { credentials: "include" }
        );

        if (!response.ok) {
          throw new Error("Error fetching documents");
        }

        this.documentsList = await response.json();

        console.log("documentsList : ", this.documentsList);
      } catch (e) {
        this.$notify({
          group: "global",
          title: "Error",
          message: "Error fetching documents",
          type: "error",
        });

        console.error("Error fetching documents", e);
      }
    },

    async viewDocument(item) {
      try {
        const response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT +
            "schedule/appointment/documents/" +
            "view-document/" +
            item.cd_syskey,
          { credentials: "include" }
        );

        if (!response.ok) {
          throw new Error("Error fetching documents");
        }

        let document = await response.blob();
        downloadjs(document, item.cd_document);
      } catch (e) {
        this.$notify({
          group: "global",
          title: "Error",
          message: "Error downloading document",
          type: "error",
        });

        console.error("viewDocument : Error fetching documents", e);
      }
    },
  },

  watch: {
    appointment: {
      handler: function() {
        this.loadDocuments();
      },
      deep: true,
    },
  }, // watch

  data: () => {
    return {
      documentsList: [],
      tableHeaders: [
        { text: "Document Type", value: "cd_type", sortable: false },
        { text: "Description", value: "cd_desc", sortable: false },
        { text: "", value: "viewAction", sortable: false },
      ],
    };
  },
};
</script>
