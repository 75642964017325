<template>
  <div>
    <v-card>
      <v-data-table
        :headers="tableHeaders"
        :items="subTasks"
        disable-pagnination
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:item.cs_completed="{ item }">
          <v-checkbox v-model="item.cs_completed" color="green"></v-checkbox>
        </template>
        <template v-slot:item.cs_not_completed="{ item }">
          <v-checkbox v-model="item.cs_not_completed" color="red"></v-checkbox>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import SelectFunctions from "../select/SelectFunctions";

export default {
  name: "AppointmentSubTasks",
  props: {
    appointment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      subTasks: [],
      tableHeaders: [
        { text: "Task", value: "cs_desc", sortable: false },
        { text: "Notes", value: "cs_notes", sortable: false },
        { text: "Completed", value: "cs_completed", sortable: false },
        { text: "Not Completed", value: "cs_not_completed", sortable: false },
      ],
    };
  },
  created() {
    this.getSubTasks();
  },
  methods: {
    async getSubTasks() {
      console.log("getSubTasks : method called : ", this.appointment.id);

      try {
        const response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT +
            "schedule/appointment/sub-tasks/" +
            this.appointment.id,
          { credentials: "include" }
        );

        if (!response.ok) {
          throw new Error("Error fetching sub tasks");
        }

        this.subTasks = await response.json();
      } catch (e) {
        this.$notify({
          group: "global",
          type: "error",
          title: "Error",
          text: "Error fetching sub tasks",
        });
        console.error("Error fetching sub tasks", e);
      }
    },
  },

  // watch to check if the id of the appointment changes
  watch: {
    appointment: {
      handler: function() {
        this.getSubTasks();
      },
      deep: true,
    },
  },
};
</script>
