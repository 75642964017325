<template>
  <div>
    <v-card>
      <v-container fluid>
        <v-form ref="form">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" xs="12">
                <v-select
                  v-model="newNote.category"
                  :items="categoryList"
                  label="Category"
                  outlined
                  dense
                  :rules="[(v) => !!v || 'Category is required']"
                ></v-select>
                <v-textarea
                  v-model="newNote.note"
                  label="Note"
                  outlined
                  dense
                  :rules="[(v) => !!v || 'Note is required']"
                ></v-textarea>
                <v-btn
                  @click="saveNewNote"
                  color="primary"
                  dense
                  style="width:100%;"
                  >Insert New Note</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-container>

      <v-card-text>
        <v-data-table
          :headers="tableHeaders"
          :items="notes"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:item.datetime="{ item }">
            {{ dayjs(item.datetime).format("DD/MM/YYYY HH:MM") }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SelectFunctions from "../select/SelectFunctions";
import dayjs from "dayjs";

export default {
  name: "AppointmentSubTasks",
  props: {
    appointment: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.loadNotes();
  },

  methods: {
    async loadNotes() {},

    resetNoteForm() {
      this.newNote = {
        category: "",
        note: "",
        datetime: "",
      };
    },

    saveNewNote() {
      if (!this.$refs.form.validate()) {
        return;
      }

      console.log("saveNewNote : method called : ", this.newNote);

      let newNote = JSON.parse(JSON.stringify(this.newNote));

      newNote.datetime = dayjs().format("YYYY-MM-DD HH:mm:ss");

      this.notes.push(newNote);

      this.resetNoteForm();
    },
  },

  computed: {
    dayjs() {
      return dayjs;
    },
  },

  watch: {
    // Check if the appointment id changes

    appointment: {
      handler: function() {
        this.loadNotes();
      },
      deep: true,
    },
  },

  data: () => {
    return {
      newNote: {
        category: "",
        note: "",
        datetime: "",
      },

      notes: [],
      tableHeaders: [
        { text: "Category", value: "category", sortable: false },
        { text: "Note", value: "note", sortable: false },
        { text: "Date / Time", value: "datetime", sortable: false },
      ],

      categoryList: ["NOTE", "MEDICATION", "DIAGNOSIS", "PROCEDURE", "OTHER"],
    };
  },
};
</script>
